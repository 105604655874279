<template>
  <div>
    <panel-alert title="绑定治疗设备" confirmButton="确定" isShowInfo isShowBottom width="650px" @onCloseClick="onCloseClick"
      @onConfirmClick="onConfirmClick">
      <template v-slot:content>
        <div class="add-patient-alert">
          <div class="add-patient-alert-row add-patient-alert-row-1">
            <div class="input-item">
              <must-label isMust text="选择设备"></must-label>
              <el-select filterable :disabled="initSelectedDevice != undefined" v-model="selectedDeviceName"
                :popper-append-to-body="false" @change="onSelectedDevice" placeholder="请选择"
                v-loadmore="onDeviceLoadMore">
                <el-option v-for="(item, index) in deviceArray" :disabled="item.uid != 0" :key="index" :label="item.sn"
                  :value="item.sn">
                  <span :class="item.uid == 0 ? 'input-item-enable' : 'input-item-disable'
      ">{{
      `${item.sn} ${item.uid == 0 ? "闲置" : item.userName}`
    }}</span>
                </el-option>
              </el-select>
            </div>
            <div class="input-item">
              <must-label isMust text="选择患者"></must-label>
              <el-select filterable :disabled="initSelectedPatient != undefined" v-model="selectedPatientName"
                :popper-append-to-body="false" @change="onSelectedPatient" placeholder="请选择"
                v-loadmore="onPatientLoadMore">
                <el-option v-for="(item, index) in patientArray" :disabled="item.sn != ''" :key="index"
                  :label="item.name" :value="item.name">
                  <div class="input-item" :class="item.sn != '' ? 'input-item-disable' : 'input-item-enable'
      ">
                    <span class="input-item-name">{{ item.name }}</span>
                    <span>{{ item.age }}</span>
                    <span v-if="item.sn != ''">{{ item.sn }}</span>
                  </div>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="add-patient-alert-row add-patient-alert-row-2">
            <div class="input-item">
              <must-label text="已选设备"></must-label>
              <p class="add-patient-alert-row-check-container-text">
                {{ selectedDeviceName }}
              </p>
            </div>
            <div class="add-patient-alert-row-check-container">
              <must-label text="已选患者"></must-label>
              <p class="add-patient-alert-row-check-container-text">
                {{ selectedPatientName }}
              </p>
              <button class="add-patient-alert-row-check-container-check" @click="onCheckUserInfo">
                查看详情
              </button>
            </div>
          </div>
        </div>
      </template>
    </panel-alert>
    <panel-alert-user-info v-if="isShowUserInfoAlert" :uid="selectedPatientID"
      @onCloseClick="onUserInfoAlertCloseClick"></panel-alert-user-info>
  </div>
</template>

<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";
import MustLabel from "@c/common/alert/panel-alert/components/must-label.vue";

import { Select, Option } from "element-ui";
import PanelAlertUserInfo from "./panel-alert-user-info.vue";
export default {
  components: {
    PanelAlert,
    MustLabel,
    elSelect: Select,
    elOption: Option,
    PanelAlertUserInfo: () =>
      import("@c/common/alert/panel-alert/panel-alert-user-info.vue"),
    PanelAlertUserInfo,
  },

  props: {
    initSelectedDevice: {
      value: Object,
    },

    initSelectedPatient: {
      value: Object,
    },
  },

  data: function () {
    return {
      //选择设备
      deviceCurrentPage: 1,
      deviceTotalPage: undefined,
      deviceArray: [],
      selectedDeviceName: undefined, //它处展示需要
      selectedDeviceID: undefined, //提交需要
      //选择患者
      patientCurrentPage: 1,
      patientTotalPage: undefined,
      patientArray: [],
      selectedPatientName: undefined, //它处展示需要
      selectedPatientID: undefined, //提交需要
      //滴定天数
      useDurationArray: ["1天", "2天", "3天", "7天"],
      selectedUseDuration: "1天",
      //用户详情弹窗
      isShowUserInfoAlert: false,
      //自动调参
      paramModel: undefined,
    };
  },

  directives: {
    loadmore: {
      inserted(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          const condition =
            parseInt(this.scrollHeight - this.scrollTop) <= this.clientHeight; //为了适配屏幕缩放
          if (condition) {
            binding.value(1);
          }
        });
      },
    },
  },

  created() {
    if (!this.initSelectedDevice) {
      this.getDevicePagingList();
    } else {
      this.selectedDeviceID = this.initSelectedDevice.id;
      this.selectedDeviceName = this.initSelectedDevice.sn;
    }

    if (!this.initSelectedPatient) {
      this.getPatientPagingList();
    } else {
      this.selectedPatientID = this.initSelectedPatient.model.id;
      this.selectedPatientName = this.initSelectedPatient.model.name;
    }
  },

  computed: {
    hospitalId() {
      return this.initSelectedPatient ? this.initSelectedPatient.model.hospitalId : (this.$store.state.organizationModel?.id ?? 0);
    }
  },

  methods: {
    //绑定弹窗
    onDeviceLoadMore() {
      if (this.deviceCurrentPage == this.deviceTotalPage) {
        return;
      }
      this.deviceCurrentPage += 1;
      this.getDevicePagingList();
    },

    onPatientLoadMore() {
      if (this.patientCurrentPage == this.patientTotalPage) {
        return;
      }
      this.patientCurrentPage += 1;
      this.getPatientPagingList();
    },

    onCloseClick: function () {
      this.$emit("onCloseClick");
    },

    async onConfirmClick() {
      if (!this.selectedDeviceID || !this.selectedPatientID) {
        this.$toast.showRed("请先选中设备和患者");
        return;
      }
      this.bindDevice()
    },

    onCheckUserInfo() {
      if (!this.selectedPatientID) {
        this.$toast.showRed("请先选中患者");
        return;
      }
      this.isShowUserInfoAlert = true;
    },

    //用户详情弹窗
    onUserInfoAlertCloseClick() {
      this.isShowUserInfoAlert = false;
    },

    //绑定弹窗
    onSelectedDevice(selectedDeviceName) {
      this.selectedDeviceID = this.deviceArray.filter((item) => {
        return item.sn == selectedDeviceName;
      })[0].id;
    },

    onSelectedPatient(selectedPatientName) {
      this.selectedPatientID = this.patientArray.filter((item) => {
        return item.name == selectedPatientName;
      })[0].id;
    },

    // method
    async getDevicePagingList() {
      try {
        const params = {
          hospitalId: this.hospitalId,
          pageNum: this.deviceCurrentPage,
          pageSize: 10,
        };
        const data = await this.$api.getDeviceList(params)
        this.deviceArray = this.deviceArray.concat(data.list);
        this.deviceTotalPage = data.pages;
        this.deviceCurrentPage = data.pageNum;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async getPatientPagingList() {
      try {
        const params = {
          pageNum: this.patientCurrentPage,
          hospitalId: this.hospitalId,
          pageSize: 10,
        };
        const data = await this.$api.getPatientList(params);
        this.patientArray = this.patientArray.concat(data.list);
        this.patientTotalPage = data.pages;
        this.patientCurrentPage = data.pageNum;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async bindDevice() {
      try {
        const params = {
          sn: this.selectedDeviceName,
          uid: this.selectedPatientID,
        };
        await this.$api.bindUserAndDevice(params);
        await this.getDeviceParams()
        await this.setupDevicePara()
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async getDeviceParams() {
      try {
        let params = {
          deviceId: this.selectedDeviceID,
          userId: this.selectedPatientID
        }
        this.paramModel = await this.$api.getBindDeviceParams(params)
      } catch (error) {
        this.$toast.showRed(error)
      }
    },

    async setupDevicePara() {
      try {
        const params = {
          "deviceId": this.selectedDeviceID,
          "maskTypeId": this.paramModel.maskTypeId,
          "userId": this.selectedPatientID,
          "parameter": JSON.stringify(this.paramModel)
        }
        await this.$api.setupDeviceParams(params)
        this.$emit("onBindSucceed", {
          deviceID: this.selectedDeviceID,
          uid: this.selectedPatientID,
        });
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-patient-alert {
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;

  &-row {
    display: flex;
    justify-content: space-between;

    &-1 {
      margin-top: 11px;
    }

    &-2 {
      margin-top: 17px;
      margin-bottom: 17px;
    }

    &-check-container {
      position: relative;
      left: -58px;
      width: 220px;
      display: flex;
      align-items: center;

      &-text {
        color: #666666;
        font-size: 14px;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 92px;
        box-sizing: border-box;
        white-space: nowrap;
      }

      &-check {
        position: absolute;
        right: 0;
        color: #2e6be6;
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
}

.input-item {
  display: flex;
  align-items: center;

  &-name {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-disable {
    font-size: 14px;
    color: rgba($color: #000000, $alpha: 0.25);
  }

  &-enable {
    font-size: 14px;
  }

  //element-ui
  /deep/.el-input__inner {
    width: 200px;
    height: 32px;
  }

  /deep/.el-input__icon {
    line-height: 32px; //图片居中
  }

  /deep/.el-select-dropdown__item {
    display: flex;
    align-items: center;
    width: 200px;

    span {
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
