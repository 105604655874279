<template>
  <panel-alert
    title="个人信息"
    width="595px"
    @onCloseClick="onCloseClick"
    @onConfirmClick="onConfirmClick"
  >
    <template v-slot:content>
      <div v-if="patientDetailModel" class="user-info-alert">
        <div class="user-info-alert-top">
          <div class="user-info-alert-top-row">
            <unit
              class="user-info-alert-unit"
              left="患者姓名"
              :right="patientDetailModel.name"
              isHideOverflow
            ></unit>
            <unit
              class="user-info-alert-unit user-info-alert-unit-right"
              left="患者ID"
              :right="patientDetailModel.number"
              isHideOverflow
            >
            </unit>
          </div>
          <div class="user-info-alert-top-row">
            <unit
              class="user-info-alert-unit"
              left="性别"
              :right="patientDetailModel.gender"
            >
            </unit>
            <unit
              class="user-info-alert-unit user-info-alert-unit-right"
              left="年龄"
              :right="patientDetailModel.age"
            ></unit>
          </div>
          <div class="user-info-alert-top-row">
            <unit
              class="user-info-alert-unit"
              left="婚姻状况"
              :right="patientDetailModel.marrage"
            ></unit>
            <unit
              class="user-info-alert-unit user-info-alert-unit-right"
              left="身高"
              :right="patientDetailModel.height"
            ></unit>
          </div>
          <div class="user-info-alert-top-row">
            <unit
              class="user-info-alert-unit"
              left="学历"
              :right="patientDetailModel.education"
            ></unit>
            <unit
              class="user-info-alert-unit user-info-alert-unit-right"
              left="职业"
              :right="patientDetailModel.occupation"
            ></unit>
          </div>
          <div class="user-info-alert-top-row">
            <unit
              class="user-info-alert-unit"
              left="体重"
              :right="patientDetailModel.weight"
            ></unit>
            <unit
              class="user-info-alert-unit user-info-alert-unit-right"
              left="BMI"
              :right="patientDetailModel.bmi"
            ></unit>
          </div>
          <div class="user-info-alert-top-row">
            <unit
              class="user-info-alert-unit"
              left="颈围"
              :right="patientDetailModel.neckCircumstance"
            ></unit>
            <unit
              class="user-info-alert-unit user-info-alert-unit-right"
              left="联系时间"
              :right="patientDetailModel.contactTime"
            ></unit>
          </div>
          <div class="user-info-alert-top-row">
            <unit
              class="user-info-alert-unit"
              left="手机号码"
              :right="patientDetailModel.mobile"
            ></unit>
          </div>
          <unit
            class="
              user-info-alert-unit user-info-alert-unit user-info-alert-long
            "
            left="居住地址"
            :right="patientDetailModel.address"
          ></unit>
        </div>
        <div class="user-info-alert-line"></div>
        <div class="user-info-alert-bottom">
          <unit
            class="
              user-info-alert-unit user-info-alert-unit user-info-alert-long
            "
            left="主诉病情"
            :right="patientDetailModel.medicineType"
          ></unit>
          <unit
            class="
              user-info-alert-unit user-info-alert-unit user-info-alert-long
            "
            left="既往病史"
            :right="patientDetailModel.medicineHistory"
          ></unit>
          <unit
            class="
              user-info-alert-unit user-info-alert-unit user-info-alert-long
            "
            left="病史信息"
            :right="patientDetailModel.medicineInfo"
          ></unit>
        </div>
      </div>
    </template>
  </panel-alert>
</template>

<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";
import Unit from "@c/common/alert/panel-alert/components/key-value-unit.vue";
import { PatientDetailModel } from "@js/model/patient-detail-model.js";
export default {
  components: {
    PanelAlert,
    Unit,
  },

  props: {
    uid: Number,
  },

  data: function () {
    return {
      patientDetailModel: undefined,
    };
  },

  created() {
    this.getPatientDetail();
  },

  methods: {
    onCloseClick() {
      this.$emit("onCloseClick");
    },

    onConfirmClick() {
      this.$emit("onConfirmClick");
    },

    async getPatientDetail() {
      try {
        const params = {
          uid: this.uid,
        };
        const data = await this.$api.getPatientDetail(params);
        this.patientDetailModel = new PatientDetailModel(data);
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-info-alert {
  position: relative;
  display: flex;
  flex-direction: column;

  &-top {
    margin-top: 4px;
    display: flex;
    flex-direction: column;

    &-row {
      position: relative;
      width: 100%;
      height: 34px;
      display: flex;
      align-items: center;
    }
  }

  &-line {
    background-color: rgba($color: #000000, $alpha: 0.09);
    height: 1px;
    margin: 52px 0px 34px;
  }

  &-bottom {
    display: flex;
    flex-direction: column;
  }

  &-unit {
    &-right {
      position: absolute;
      left: 293px;
    }
  }

  &-long {
    margin-top: 10px;
    min-height: 28px;
  }
}
</style>